require('./bootstrap');
require('bootstrap-datepicker');
/*import Cleave from 'cleave.js';*/

$.fn.datepicker.defaults.autoclose = true;

function updateDisabledDates(dates) {
    $('#date').datepicker('setDatesDisabled', dates);
}

window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}


document.addEventListener('DOMContentLoaded', () => {
    Livewire.on('packageSelected', disabledDates => {
        updateDisabledDates(disabledDates);
    });
    Livewire.on('pushAnalytics', analyticsContent => {
        /*gtag('event', analyticsContent.package_name, {
            'event_category': 'Booking Success',
            'event_label': analyticsContent.payment_method,
            'value': analyticsContent.price
        });*/
        /*var items = [];
        analyticsContent.items.forEach(function(item)) {
          items.push({
            'item_id': item.id,
            'item_name': item.name,
            'affiliation': 'lyrarakis.com',
            'currency': 'EUR',
            'item_category': analyticsContent.package_name,
            'price': item.price,
            'quantity': item.quantity
          });
        }*/
        /*gtag('event', analyticsContent.package_name, {
            'event_category': 'Booking Success',
            'event_label': analyticsContent.payment_method,
            'value': analyticsContent.price
        });
        gtag('event', 'purchase', {
            'transaction_id': analyticsContent.transaction_id,
            'affiliation': "lyrarakis.com",
            'value': analyticsContent.price,
            'currency': "EUR",
            'payment_type': analyticsContent.payment_method,
            'items': analyticsContent.items
        });*/
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          "event": analyticsContent.package_name,
          "eventData": {
            "category": 'Booking Success',
            "label": analyticsContent.payment_method,
            "value": analyticsContent.price
          }
        });
        window.dataLayer.push({
          "event": "newBooking",
          "eventData": {
            "transaction_id": analyticsContent.transaction_id,
            "affiliation": "lyrarakis.com",
            "value": analyticsContent.price,
            "currency": "EUR",
            "email": analyticsContent.email,
            "payment_type": analyticsContent.payment_method,
            "item": analyticsContent.items
          }
        });
        console.log(analyticsContent.items);
        /*if(typeof fbq === 'undefined') {
            console.log('fbq is undefined');
        }
        else {
            fbq('track', 'Purchase', {
                value: analyticsContent.price,
                currency: 'EUR'
            });
        }*/
    });
/*    Livewire.on('creditCardSelected', () => {
        var cardCleave = new Cleave('#card', {
            creditCard: true,
            onCreditCardTypeChanged: function (type) {
                // update UI ...
            }
        });
        var expireCleave = new Cleave('#expire', {
            date: true,
            datePattern: ['m', 'y']
        });
    });*/
    Livewire.on('submitBank', () => {
        $('.main-wrapper').addClass('loading');
        $('#form1').submit();
    });
    Livewire.on('changedStep', () => {
        window.scrollTo(0,0);
    });
/*    document.getElementById('collapseDetailsToggler').addEventListener('click', function() {

    });*/
});
